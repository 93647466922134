import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";

/* custom Hooks and stylesheet */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import "./index.scss";
import "./assets/lib/fontawesome/css/all.css";
import ProtectedRoutes from "./components/protected-routes/ProtectedRoutes";
import { isUserLoggedGuard } from "./middleware/guard";

/* components */
const Landing = lazy(() => import("./pages/Landing/Landing"));
const Cours = lazy(() => import("./pages/Cours/Cours"));
const Chat = lazy(() => import("./pages/Chat/Chat"));
// const HomeAdmins = lazy(() => import("./pages/Home/Admins/Admins"));
const HomeEtudiant = lazy(() => import("./pages/Home/Etudiant/Etudiant"));
const AnimeLabEditor = lazy(() =>
  import("./components/AnimeLab/Editeur/AnimLabEditor")
);
const AnimeLabCours = lazy(() =>
  import("./components/AnimeLab/Cours/AnimeLabCours")
);
const AnimeLab = lazy(() => import("./pages/AnimeLab/AnimeLab"));
const RendezVous = lazy(() => import("./pages/Agenda/Agenda"));
const HomeFormateur = lazy(() => import("./pages/Home/Formateur/Formateur"));
const Lecon = lazy(() => import("./pages/Lecon/Lecon"));
const NotFound = lazy(() => import("./pages/NotFound/NotFound"));
const Profile = lazy(() => import("./pages/Profile/Profile"));
const Loader = lazy(() => import("./components/Loader/Loader"));
const Connexion = lazy(() => import("./pages/auth/connexion/Connexion"));
const Inscription = lazy(() => import("./pages/auth/inscription/Inscription"));
const VideoChat = lazy(() => import("./pages/VideoChat/VideoChat"));
const Wrapper = lazy(() => import("./components/Wrapper/Wrapper"));
const Moodle = lazy(() => import("./components/moodle/Moodle"));
const NosFormation = lazy(() => import("./pages/NosFormation/NosFormation"));
const ProcedureAdmission = lazy(() =>
  import("./pages/ProcedureAdmission/ProcedureAdmission")
);
const WrapperEtudiant = lazy(() =>
  import("./pages/etudiant/WrapperEtudiant/WrapperEtudiant")
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Suspense fallback={<Loader />}>
          <HelmetProvider>
            <Routes>
              <Route path="/" index element={<Landing />} />
              <Route path="/home" element={<Landing />} />
              <Route path="/auth/inscription" element={<Inscription />} />
              <Route
                path="/auth/connexion"
                element={
                  <ProtectedRoutes
                    isAllowed={isUserLoggedGuard() === true ? false : true}
                    redirectPath="/formateur"
                  >
                    <Connexion />
                  </ProtectedRoutes>
                }
              />
              <Route path="*" element={<NotFound />} />
              <Route path="/loader" element={<Loader />} />
              <Route path="/nos-formation" element={<NosFormation />} />
              <Route
                path="/procedure-admission"
                element={<ProcedureAdmission />}
              />
              {/* Application routes  pour formateur*/}
              <Route
                path="/formateur"
                element={
                  <ProtectedRoutes>
                    <Wrapper />
                  </ProtectedRoutes>
                }
              >
                <Route path="" element={<HomeFormateur />} />
                <Route
                  path="/formateur/anime-lab/editeur"
                  element={<AnimeLabEditor />}
                />
                <Route
                  path="/formateur/anime-lab/cours"
                  element={<AnimeLabCours />}
                />
                <Route
                  path="/formateur/anime-lab"
                  element={<AnimeLab />}
                ></Route>
                <Route path="/formateur/rendez-vous" element={<RendezVous />} />
                <Route path="/formateur/cours" element={<Cours />} />
                <Route path="/formateur/saifair-konect" element={<Chat />} />
                <Route path="/formateur/chat/video" element={<VideoChat />} />
                <Route path="/formateur/home" element={<HomeFormateur />} />
                <Route path="/formateur/lecon" element={<Lecon />} />
                <Route path="/formateur/profile/:id" element={<Profile />} />
                <Route
                  path="/formateur/moodle"
                  element={<Moodle />}
                  loc="http://192.168.1.111:80/moodle"
                />
              </Route>
              {/* Application routes pour etudiant */}
              <Route path="/etudiant" element={<WrapperEtudiant />}>
                <Route path="" element={<HomeEtudiant />} />
              </Route>

              {/* Application routes pour administrateur */}
            </Routes>
          </HelmetProvider>
        </Suspense>
      </Router>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
