import React from "react";
import "./style.scss";
import logo from "../../assets/images/Saifair_academy_logo.png";

function Loader() {
  return (
    <div className="Loader">
      <img src={logo} alt="logo" className="Logo" />
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default Loader;
