import { createSlice } from "@reduxjs/toolkit";

// project: {
//     title: null,
//     desc: null,
//     currentPage: null,
//     pages: []
// }

const projectAnimLabSlice = createSlice({
  name: "project-anim-lab",
  initialState: {
    rendered: null,
    project: null
  },
  reducers: {
    setRendered(state, action) {
        state.rendered = action.payload;
    },
    unsetRendered(state, action) {
        state.rendered = null;
    },
    setProject(state, action) {
        state.project = action.payload;
    },
    setProjectTitle(state, action) {
        state.project.title = action.payload;
    },
    setProjectDescription(state, action) {
        state.project.desc = action.payload;
    },
    setCurrentPage(state, action) {
        state.project.currentPage = action.payload;
    },
    setPages(state, action) {
      state.project.pages = action.payload;
    },
    addPage(state, action) {
        state.project.pages = [
            ...state.project.pages,
            action.payload
        ];
    },
    setStack(state, action) {
      if (action.payload.hasOwnProperty("page")) {
        const page = action.payload.page;
        if (page > -1) {
          state.project.pages[page].stack = action.payload.stack;
        }
      }
    },
    pushStack(state, action) {
      if (action.payload.hasOwnProperty("page")) {
        const page = action.payload.page;
        if (page > -1) {
          state.project.pages[page].stack = [
            ...state.project.pages[page].stack,
            action.payload.element,
          ];
        }
      }
    },
    popStack(state, action) {
      if (action.payload.hasOwnProperty("page")) {
        const page = action.payload.page;
        if (page > -1) {
          const lastIndex = state.pages[page].stack.length - 1;
          if (lastIndex > -1) {
            state.project.pages[page].stack = [
              ...state.project.pages[page].stack.slice(0, lastIndex),
              ...state.project.pages[page].stack.slice(lastIndex + 1),
            ];
          }
        }
      }
    },
  },
});

export const { setRendered, unsetRendered, setProject, setProjectTitle, setProjectDescription, setCurrentPage, setPages,  addPage, setStack, pushStack, popStack } = projectAnimLabSlice.actions;
export default projectAnimLabSlice.reducer;
