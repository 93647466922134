import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isUserLoggedGuard } from "../../middleware/guard";
import Loader from "../Loader/Loader";
const ProtectedRoutes = ({
  isAllowed = isUserLoggedGuard(),
  redirectPath = "/auth/connexion",
  children,
}) => {
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAllowed) {
      navigate(redirectPath);
      return;
    }
    setIsVerified(true);
  }, [isAllowed , navigate, redirectPath]);

  return isVerified ? children : <Loader/>;
};
export default ProtectedRoutes;
